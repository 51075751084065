<template>
  <div class="markup-tables flex">
    <va-card class="flex mb-4">
      <va-card-title style="font-size: 1.5rem;">Sessions</va-card-title>
      <va-card-content>
        <div class="row">
          <va-input
            class="flex mb-2 md6"
            placeholder="Filter..."
            v-model="filter"
          />
          <div class="flex mb-2 md6 text-right" style="padding-right: 4rem;" v-if="selectedItems.length > 0">
            <va-icon :size="'1.6rem'" :name="'material-icons-grid_on'" style="cursor: pointer; color: #154ec1;" @click="visualizeImages" />
          </div>
        </div>
        <va-data-table
          :items="sessions"
          :columns="columns"
          :per-page="perPage"
          :current-page="currentPage"
          :filter="filter"
          :selectable="true"
          v-model="selectedItems"
          @filtered="setFiltered"
        >
          <template #bodyAppend>
            <tr><td colspan="8" class="table-example--pagination">
              <va-pagination
                v-model="currentPage"
                input
                :pages="pages"
              />
            </td></tr>
          </template>
          <template #cell(uid)="{ source: uid }">
            <router-link :key="uid" :to="{ name: 'files', params: { id: uid }}">
              <va-icon :name="'fa4-eye'"/>
            </router-link>
            <va-icon :name="'fa4-trash'" style="padding-left: 1rem; cursor: pointer; color: red;" @click="removeModal(uid, label)" />
          </template>
        </va-data-table>
        <va-alert class="mt-3" border="left" v-if="filter.length > 0">
          <span>
            Number of filtered items:
            <va-chip>{{ filteredCount }}</va-chip>
          </span>
        </va-alert>
      </va-card-content>
    </va-card>

    <va-modal
      v-model="removeModalShow"
      hide-default-actions
      overlay-opacity="0.2"
      size="large"
    >
      <template #header>
        <h2>Remove a session</h2>
      </template>
      <slot>
        <div>Do you want to remove {{ removeName }}?</div>
      </slot>
      <template #footer>
        <va-button class="mr-2 mb-2" color="gray" @click="removeModalShow = false">Cancel</va-button>
        <va-button class="mr-2 mb-2" color="danger" @click="removeModalConfirm">Delete</va-button>
      </template>
    </va-modal>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
  mounted: async function () {
    this.loader = this.$loading.show()
    this.$store.commit('setSelectedItems', [])
    this.sessionsObject = {}
    try {
      const headers = {
        Authorization: `Bearer ${this.token}`
      }
      // Load a list of sessions
      const sessions = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions`, { headers })
      this.sessions = await Promise.all(sessions.data.sessions.map((session) => {
        session.created_at = moment(session.created_at).format('YYYY-MM-DD hh:mm a')
        this.sessionsObject[session.uid] = session.label
        return session
      }))
      this.filteredCount = this.sessions.length
    } catch (error) {
      this.$router.push({ name: 'login', query: { redirect: '/pages/sessions' } })
    }
    this.loader.hide()
  },
  data () {
    return {
      loader: null,
      token: this.$store.state.token,
      sessions: [],
      sessionsObject: {},
      selectedItems: [],
      columns: [
        {
          key: 'label',
          label: 'Sessions',
          headerTitle: 'Sessions',
          sortable: true
        },
        {
          key: 'user',
          label: 'User',
          headerTitle: 'User',
          sortable: true
        },
        {
          key: 'created_at',
          label: 'Created on',
          headerTitle: 'Created on',
          sortable: true
        },
        {
          key: 'uid',
          label: 'Action',
          headerTitle: 'Action'
        }
      ],
      filter: '',
      filtered: [],
      filteredCount: 0,
      perPage: 10,
      currentPage: 1,
      removeModalShow: false,
      removeId: null,
      removeName: null
    }
  },
  computed: {
    pages () {
      return (this.perPage && this.perPage !== 0)
        ? Math.ceil(this.filtered.length / this.perPage)
        : this.filtered.length
    },
  },
  methods: {
    removeModal (id) {
      this.removeId = id
      this.removeName = this.sessionsObject[id]
      this.removeModalShow = true
    },
    removeModalConfirm: async function () {
      this.loader = this.$loading.show()
      try {
        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        // Delete a session
        await axios.delete(`${process.env.VUE_APP_API_URL}/api/sessions/${this.removeId}`, { headers })
        // Show success message
        this.$vaToast.init({
          message: 'Session deleted successfully!',
          iconClass: 'fa-star-o',
          position: 'bottom-right',
          duration: 6000,
          fullWidth: false,
          color: 'primary',
        })

        // Load a list of session again
        const sessions = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions`, { headers })
        this.sessions = await Promise.all(sessions.data.sessions.map((session) => {
          session.created_at = moment(session.created_at).format('YYYY-MM-DD hh:mm a')
          return session
        }))

        this.removeId = null
        this.removeName = null
        this.removeModalShow = false
      } catch (error) {
        this.removeId = null
        this.removeName = null
        this.removeModalShow = false
        this.$router.push({ name: 'login', query: { redirect: '/pages/sessions' } })
      }
      this.loader.hide()
    },
    setFiltered (event) {
      this.filteredCount = event.items.length
      this.filtered = event.items
    },
    visualizeImages: async function () {
      this.$store.commit('setSelectedItems', this.selectedItems)
      this.$router.push({ name: 'grid' })
    }
  },
}
</script>

<style lang="scss">
  .markup-tables {
    .table-wrapper {
      overflow: auto;
    }

    .va-table {
      width: 100%;
    }
  }

  .table-example--pagination {
    text-align: center;
    text-align: -webkit-center;
    padding-top: 1rem;
  }

  .link:hover {
    text-decoration: underline;
  }
</style>
